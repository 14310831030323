import {
  UPDATE_SIGNS,
  UPDATE_LOADING,
  UPDATE_STREET_VIEW_IMAGE,
  UPDATE_SIGNS_CACHE,
  CREATE_SUB_FILTERED_SIGNS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_CACHE,
  UPDATE_ORDERS,
  UPDATE_ORDERS_CACHE,
  UPDATE_SIGN_COUNTS,
  FILTER_SEARCH_HISTORY,
  CLEAR_ORDERS,
  RESET_DATA,
  UPDATE_STREETS,
} from '../actionTypes/SignsActionTypes'

const initialState = {
  signs: [],
  signCounts: 0,
  orders: [],
  loading: false,
  streetViewImage: '',
  cache: {
    signs: [],
    streetViewImage: '',
    orders: [],
  },
  subFiltered: {},
  address: '55 Water Street, New York, NY, United States',
  addressCache: '',
  filterSearchHistory: [],
  streets: [],
}

const SignsState = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DATA:
      return {
        ...state,
        ...initialState,
      }
    case UPDATE_SIGNS:
      return {
        ...state,
        signs: action.signs,
      }
    case UPDATE_LOADING:
      return {
        ...state,
        loading: action.value,
      }
    case UPDATE_STREET_VIEW_IMAGE:
      return {
        ...state,
        streetViewImage: action.value,
      }
    case UPDATE_SIGNS_CACHE:
      return {
        ...state,
        cache: { ...state.cache, ...action.data },
      }
    case CREATE_SUB_FILTERED_SIGNS:
      return {
        ...state,
        subFiltered: action.signs,
      }
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.address,
      }
    case UPDATE_ADDRESS_CACHE:
      return {
        ...state,
        addressCache: action.address,
      }
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...action.orders],
      }
    case UPDATE_ORDERS_CACHE:
      return {
        ...state,
        cache: {
          ...state.cache,
          orders: [...action.orders],
        },
      }
    case UPDATE_SIGN_COUNTS:
      return {
        ...state,
        signCounts: action.value,
      }
    case FILTER_SEARCH_HISTORY:
      return {
        ...state,
        filterSearchHistory: action.value,
      }
    case CLEAR_ORDERS:
      return {
        ...state,
        cache: initialState.cache,
        orders: [],
        subFiltered: {},
      }
    case UPDATE_STREETS:
      return {
        ...state,
        streets: action.value,
      }
    default:
      return state
  }
}

export default SignsState
