import React from 'react'
import { toast } from 'react-toastify'
import { maxRadius, fallBackRadius } from '../../../config'
import General from '../../../components/ToastsComponents/General'

export const cacheItems = (lat, lng, radius) => ({
  center: [lng, lat],
  radius: radius,
})

export const validateRadius = radius =>
  radius > maxRadius ? fallBackRadius : radius

const toastType = {
  Error: toast.TYPE.ERROR,
  Default: toast.TYPE.DEFAULT,
  Info: toast.TYPE.INFO,
  Warning: toast.TYPE.WARNING,
  Success: toast.TYPE.SUCCESS,
}

export const notify = ({ content, type, className }, autoClose = true) => {
  toast(<General content={content} />, {
    type: toastType[type],
    className: className,
    autoClose,
  })
}
