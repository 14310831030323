import React from 'react'
import { toast } from 'react-toastify'
import {
  UPDATE_CATEGORIES,
  UPDATE_SUBCATEGORIES,
  UPDATE_MYLOCATION,
  UPDATE_WATCHLOCATION,
  UPDATE_CATEGORY,
  UPDATE_COMPASSROTATE,
  UPDATE_SUBCATEGORY,
  TOGGLE_SHAKE_SEARCH,
  CHANGE_THEME,
  NETWORK_STATUS,
  TOGGLE_DRAWING,
  UPDATE_HISTORY,
} from '../actionTypes/SpiActionTypes'
import { fetchCategories, fetchSubCategories } from '../../utils/api'

const updateCategories = categories => ({ type: UPDATE_CATEGORIES, categories })

const updateSubCategories = subCategories => ({
  type: UPDATE_SUBCATEGORIES,
  subCategories,
})

const updateNetworkStatus = value => ({ type: NETWORK_STATUS, value })

//EXPORTS///////////////////////////////////////////////////////////////////////
export const changeTheme = theme => ({ type: CHANGE_THEME, theme })

export const toggleShakeSearch = value => ({ type: TOGGLE_SHAKE_SEARCH, value })

export const updateSubCategory = value => ({ type: UPDATE_SUBCATEGORY, value })

export const toggleCompassRotate = value => ({
  type: UPDATE_COMPASSROTATE,
  value,
})

export const turnOnMyLocation = value => ({ type: UPDATE_MYLOCATION, value })

export const turnOnWatchLocation = value => ({
  type: UPDATE_WATCHLOCATION,
  value,
})

export const toggleDrawing = value => ({ type: TOGGLE_DRAWING, value })

export const updateHistory = value => ({ type: UPDATE_HISTORY, value })

export const handleNetworkStatus = online => dispatch => {
  if (!online) {
    dispatch(updateNetworkStatus(online))
    toast.info(
      ({ closeToast }) => (
        <div>Your device lost connection. Trying to Reconnect.... </div>
      ),
      { autoClose: false }
    )
  } else {
    toast.dismiss()
    dispatch(updateNetworkStatus(online))
  }
}

export const getCategories = () => async dispatch => {
  const categories = await fetchCategories()
  await dispatch(updateCategories(categories.data.data))
}

export const getSubCategories = (CategoryID = 21) => async dispatch => {
  const subCategories = await fetchSubCategories(CategoryID)
  await dispatch(updateSubCategories(subCategories.data.data))
}

export const updateCategory = category => ({ type: UPDATE_CATEGORY, category })

export const handleUpdateCategory = category => async dispatch => {
  await dispatch(updateCategory(category))
  await dispatch(getSubCategories(category[1]))
}
