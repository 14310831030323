import geolib from 'geolib'
import {
  SET_MAP_REFERENCE,
  UPDATE_ZOOM,
  UPDATE_CENTER,
  UPDATE_RADIUS,
  UPDATE_CACHE,
  UPDATE_ANGLE,
  UPDATE_CLUSTER_COLOR,
  UPDATE_STREET_VIEW_CENTER,
  UPDATE_INFOBOX,
  SAVE_COORD_PARAMS,
  UPDATE_POLYGON,
  TOGGLE_SATELLITE,
  ADDRESS_BASED_SEARCH,
} from '../actionTypes/MapActionTypes'
import { getNumberOfSigns } from './index'

//EXPORTS///////////////////////////////////////////////////////////////////////
export const setMapReference = mapReference => ({
  type: SET_MAP_REFERENCE,
  mapReference,
})

export const updateRadius = radius => ({ type: UPDATE_RADIUS, radius })

export const updateClusterColor = value => ({
  type: UPDATE_CLUSTER_COLOR,
  value,
})

export const updateAngle = value => ({ type: UPDATE_ANGLE, value })

export const updateZoom = value => ({ type: UPDATE_ZOOM, value })

export const updateCenter = value => ({ type: UPDATE_CENTER, value })

export const updateInfobox = value => ({ type: UPDATE_INFOBOX, value })

export const saveCoordParams = value => ({ type: SAVE_COORD_PARAMS, value })

export const updatePolygon = value => ({ type: UPDATE_POLYGON, value })

export const addressBasedSearch = value => ({
  type: ADDRESS_BASED_SEARCH,
  value,
})

export const getViewPortRadius = bounds => async dispatch => {
  let ne = {
    latitude: bounds._ne.lat,
    longitude: bounds._ne.lng,
  }
  let sw = {
    latitude: bounds._sw.lat,
    longitude: bounds._sw.lng,
  }
  await dispatch(updateRadius(geolib.getDistance(ne, sw)))
}

export const updateCache = value => ({ type: UPDATE_CACHE, value })

export const updateStreetViewCenter = value => ({
  type: UPDATE_STREET_VIEW_CENTER,
  value,
})

export const handleUpdatePolygon = value => async dispatch => {
  let polygonForParams = value.map(pol => pol.join(':')).join(',')
  await dispatch(updatePolygon(value))
  await dispatch(saveCoordParams(polygonForParams))
  await dispatch(getNumberOfSigns(polygonForParams))
}

export const toggleSatellite = value => ({ type: TOGGLE_SATELLITE, value })
