const Colors = [
  '#2196F3',
  '#673AB7',
  '#3F51B5',
  '#636e72',
  '#009688',
  '#e17055',
  '#4CAF50',
  '#FFC107',
  '#55efc4',
  '#81ecec',
  '#74b9ff',
  '#a29bfe',
  '#6c5ce7',
  '#0984e3',
  '#00cec9',
  '#00b894',
  '#ffeaa7',
]

export default Colors
