export const UPDATE_SIGNS = 'UPDATE_SIGNS'
export const UPDATE_LOADING = 'UPDATE_LOADING'
export const UPDATE_STREET_VIEW_IMAGE = 'UPDATE_STREET_VIEW_IMAGE'
export const UPDATE_SIGNS_CACHE = 'UPDATE_SIGNS_CACHE'
export const CREATE_SUB_FILTERED_SIGNS = 'CREATE_SUB_FILTERED_SIGNS'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_ADDRESS_CACHE = 'UPDATE_ADDRESS_CACHE'
export const UPDATE_ORDERS = 'UPDATE_ORDERS'
export const UPDATE_ORDERS_CACHE = 'UPDATE_ORDERS_CACHE'
export const UPDATE_SIGN_COUNTS = 'UPDATE_SIGN_COUNTS'
export const FILTER_SEARCH_HISTORY = 'FILTER_SEARCH_HISTORY'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'
export const RESET_DATA = 'RESET_DATA'
export const UPDATE_STREETS = 'UPDATE_STREETS'
