import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import combinedStore from './store'
import 'semantic-ui-css/semantic.min.css'
import './assets/css/main.css'
import App from './components/App'
import registerServiceWorker from './utils/serviceWorker/registerServiceWorker'

ReactDOM.render(
  <Provider store={combinedStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
