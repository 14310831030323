import { createStore, applyMiddleware, compose } from 'redux'
import { persistentStore } from 'redux-pouchdb'
import PouchDB from 'pouchdb-browser'
import logger from 'redux-logger'
import Thunk from 'redux-thunk'
import stores from './Reducers'
import { enableLogger } from '../config'

const spiDB = new PouchDB('spiDB')

const composeEnhancers = compose

const applyMiddlewares = enableLogger
  ? applyMiddleware(Thunk, logger)
  : applyMiddleware(Thunk)

const createStoreWithMiddleWares = composeEnhancers(
  applyMiddlewares,
  persistentStore(spiDB)
)(createStore)

const combinedStore = createStoreWithMiddleWares(stores)

export default combinedStore
