import {
  UPDATE_LOADING,
  UPDATE_SIGNS,
  UPDATE_STREET_VIEW_IMAGE,
  UPDATE_SIGNS_CACHE,
  CREATE_SUB_FILTERED_SIGNS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_CACHE,
  UPDATE_ORDERS,
  UPDATE_ORDERS_CACHE,
  UPDATE_SIGN_COUNTS,
  FILTER_SEARCH_HISTORY,
} from '../actionTypes/SignsActionTypes'
import { googleApiKey, streetViewImageUrl } from '../../config'
import { signUrl } from '../../utils/gsSignature'

const updateStreetViewImage = value => ({
  type: UPDATE_STREET_VIEW_IMAGE,
  value,
})

const createFilteredSigns = signs => ({
  type: CREATE_SUB_FILTERED_SIGNS,
  signs,
})

//EXPORTS///////////////////////////////////////////////////////////////////////

export const updateOrders = orders => ({ type: UPDATE_ORDERS, orders })

export const updateOrdersCache = orders => ({
  type: UPDATE_ORDERS_CACHE,
  orders,
})

export const updateLoading = value => ({ type: UPDATE_LOADING, value })

export const updateSigns = signs => ({ type: UPDATE_SIGNS, signs })

export const updateSignsCache = data => ({ type: UPDATE_SIGNS_CACHE, data })

export const updateAddress = address => ({ type: UPDATE_ADDRESS, address })

export const updateAddressCache = address => ({
  type: UPDATE_ADDRESS_CACHE,
  address,
})

export const updateSignCounts = value => ({ type: UPDATE_SIGN_COUNTS, value })

export const filterSearchHistory = value => ({
  type: FILTER_SEARCH_HISTORY,
  value,
})

export const getStreetViewImage = center => async dispatch => {
  let image = signUrl(
    `${streetViewImageUrl}${center.join(',')}&client=${googleApiKey}`
  )
  await dispatch(updateStreetViewImage(image))
  await dispatch(updateSignsCache({ streetViewImage: image }))
}

export const filterBySub = signs => async dispatch => {
  let filteredSigns = {}
  signs.forEach(sign => {
    filteredSigns[sign.properties.smo_subtype] = []
  })
  signs.forEach(sign => {
    filteredSigns[sign.properties.smo_subtype].push(sign)
  })

  await dispatch(createFilteredSigns(filteredSigns))
}
