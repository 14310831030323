import {
  TOGGLE_SIDEBAR,
  TOGGLE_SEARCH_CONTENT,
  UPDATE_SHOW_ADDRESS_SEARCH,
  TOGGLE_SEARCH_CONTENT_FOOTER,
  TOGGLE_STREET_VIEW,
  UPDATE_CHART_DATA,
  TOGGLE_SUBTYPE_MENU,
  TOGGLE_CYCLORAMA,
  TOGGLE_DOWNLOADS,
  TOGGLE_LIST,
  IS_IE,
  RESET_DATA,
  FILTER_STREETS_VALUE,
} from '../actionTypes/UiActionTypes'

const initialState = {
  sidebarOpen: false,
  searchContent: true,
  searchContentFooter: false,
  showAddressSearch: false,
  streetViewShow: false,
  chartData: {},
  showSubTypeMenu: false,
  cycloramaShow: false,
  showDownloads: false,
  listShow: false,
  ie: false,
  filterStreetsValue: [],
}

const SpiUi = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DATA:
      return {
        ...state,
        ...initialState,
        ...{ ie: state.ie },
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.value,
      }
    case TOGGLE_SEARCH_CONTENT:
      return {
        ...state,
        searchContent: action.value,
      }
    case TOGGLE_SEARCH_CONTENT_FOOTER:
      return {
        ...state,
        searchContentFooter: action.value,
      }
    case UPDATE_SHOW_ADDRESS_SEARCH:
      return {
        ...state,
        showAddressSearch: action.show,
      }
    case TOGGLE_STREET_VIEW:
      return {
        ...state,
        streetViewShow: action.show,
      }
    case UPDATE_CHART_DATA:
      return {
        ...state,
        chartData: action.chartData,
      }
    case TOGGLE_SUBTYPE_MENU:
      return {
        ...state,
        showSubTypeMenu: action.value,
      }
    case TOGGLE_CYCLORAMA:
      return {
        ...state,
        cycloramaShow: action.value,
      }
    case TOGGLE_DOWNLOADS:
      return {
        ...state,
        showDownloads: action.value,
      }
    case TOGGLE_LIST:
      return {
        ...state,
        listShow: action.value,
      }
    case IS_IE:
      return {
        ...state,
        ie: action.value,
      }
    case FILTER_STREETS_VALUE:
      return {
        ...state,
        filterStreetsValue: action.value,
      }
    default:
      return state
  }
}

export default SpiUi
