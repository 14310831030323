import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import { disclaimerDays } from '../../config'
import Disclaimer from '../Elements/SearchContent/Disclaimer'

class MainDisclaimer extends Component {
  state = {
    modal: false,
  }

  onCloseModal = () => {
    localStorage.setItem('disclaimer', new Date())
    this.setState({ modal: false })
  }

  componentDidMount = () => {
    let disclaimer = localStorage.getItem('disclaimer')
    if (!disclaimer || (disclaimer && this.getDaysElapsed(disclaimer))) {
      this.setState({ modal: true })
    }
  }

  getDaysElapsed = disclaimer => {
    let now = moment(new Date())
    let storedDate = moment(new Date(disclaimer))
    let diff = moment.duration(storedDate.diff(now))
    let elapsedDays = diff.asDays()
    let totalDifference = elapsedDays > disclaimerDays
    return totalDifference
  }

  render() {
    if (this.state.modal) {
      return (
        <Modal
          open={this.state.modal}
          onClose={this.onCloseModal}
          center
          classNames={{
            modal: 'main-disclaimer',
            overlay: 'main-disclaimer-overlay',
          }}
        >
          <Disclaimer
            mainClassName="main-disclaimer"
            headingClassName="disclaimer-heading"
          />
        </Modal>
      )
    }
    return null
  }
}

export default MainDisclaimer
