import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import Loadable from 'react-loadable'
import BlockUi from 'react-block-ui'
import { Container } from './styles/App.styles'
import Loading from '../../assets/img/loader.gif'
import BrowserUpdate from '../BrowserUpdate'
import MainDisclaimer from '../MainDisclaimer'

const LoadingComponent = () => (
  <Fragment>
    <div className="loadingContainer">
      <img className="loadingImage" src={Loading} alt="Loading animation" />
    </div>
  </Fragment>
)

const DisplayType = Loadable({
  loader: () => import('../DisplayType'),
  loading() {
    return <LoadingComponent />
  },
})

const ElementsGroup = Loadable({
  loader: () => import('../Elements'),
  loading() {
    return <LoadingComponent />
  },
})

class Contents extends PureComponent {
  state = {
    oldBrowser: false,
  }

  oldBrowserDetected = () => {
    this.setState({ oldBrowser: true })
  }
  render() {
    return (
      <Fragment>
        <BrowserUpdate oldBrowserDetected={this.oldBrowserDetected} />
        {!this.state.oldBrowser && (
          <BlockUi
            className="cyclorama-loading"
            blocking={this.props.cycloramaLoading}
            message="Cyclorama Loading, please wait"
          >
            <Container>
              <MainDisclaimer />
              <DisplayType />
              <ElementsGroup />
            </Container>
          </BlockUi>
        )}
      </Fragment>
    )
  }
}

Contents.propTypes = {
  cycloramaLoading: bool.isRequired,
}

const mapStateToProps = ({ cycloramaScripts }) => ({
  cycloramaLoading: cycloramaScripts.cycloramaLoading,
})

export default withRouter(connect(mapStateToProps)(Contents))
