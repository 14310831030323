import { combineReducers } from 'redux'
import Spi from './Spi'
import SpiUi from './SpiUi'
import MapState from './MapState'
import SignsState from './SignsState'
import CycloramaScripts from './CycloramaScripts'

const reducers = {
  spi: Spi,
  ui: SpiUi,
  map: MapState,
  signsState: SignsState,
  cycloramaScripts: CycloramaScripts,
}

const stores = combineReducers(reducers)

export default stores
