import { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { is_ie } from '../../store/Actions/UiActions'
import { getFromParamsData, clearSearch } from '../../store/Actions'
import { updateLoading } from '../../store/Actions/SignsActions'
import withAnalytics from '../Analytics'

class GetParams extends Component {
  componentDidMount = () => {
    this.handleParams()
    this.props.is_ie()
  }

  handleParams = async () => {
    toast.dismiss()
    this.props.registerPageView({ route: this.props.match.url })
    let paramsCount = Object.keys(this.props.match.params).length
    if (paramsCount === 0) {
      this.props.clearSearch()
      this.props.updateLoading(false)
    } else {
      this.props.updateLoading(true)
      let params = this.props.match.params
      let latLng = params.latLng
      let category = params.category.split(',')
      let radius = parseInt(params.radius, 10)
      await this.props.getFromParamsData(category, latLng, radius)
    }
  }

  componentDidUpdate = nextProps => {
    if (this.props.online) {
      this.handleParams()
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return true
  }

  render() {
    return null
  }
}

GetParams.propTypes = {
  online: bool.isRequired,
  updateLoading: func.isRequired,
  is_ie: func.isRequired,
  registerPageView: func.isRequired,
}

const mapStateToProps = ({ spi }) => ({
  online: spi.online,
})

const mapDispatchToProps = dispatch => ({
  getFromParamsData: (category, latLng, radius) =>
    dispatch(getFromParamsData(category, latLng, radius)),
  clearSearch: () => dispatch(clearSearch()),
  updateLoading: value => dispatch(updateLoading(value)),
  is_ie: () => dispatch(is_ie()),
})

export default withAnalytics(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GetParams))
)
