import React from 'react'
import { string } from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { title } from '../../config'

const Head = ({ theme, address, siteTitle }) => (
  <Helmet>
    <meta name="theme-color" content={theme} />
    <meta property="og:title" content={siteTitle} />
    <meta property="og:description" content={address} />
    <meta name="twitter:title" content={siteTitle} />
    <meta name="twitter:description" content={address} />
    <meta itemprop="name" content={siteTitle} />
    <meta itemprop="description" content={address} />
    <title>{`${siteTitle}-${address}`}</title>
  </Helmet>
)

Head.defaultProps = {
  siteTitle: title,
}

Head.propTypes = {
  siteTitle: string.isRequired,
  theme: string.isRequired,
}

const mapStateToProps = ({ signsState, spi }) => ({
  theme: spi.theme,
  address: signsState.address,
})

export default connect(mapStateToProps)(Head)
