import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { delay, uniqBy } from 'lodash'
import { RESET_DATA, UPDATE_STREETS } from '../actionTypes/SignsActionTypes'
import { getLatestState } from './StoreData'
import { validateRadius, notify } from './Utils'
import { preSearchTasks, postValidResponseTasks } from './Utils/Tasks'
import {
  updateLoading,
  updateSignsCache,
  updateAddress,
  updateOrders,
  updateOrdersCache,
  updateSignCounts,
  filterSearchHistory,
  updateAddressCache,
} from './SignsActions'
import { updateSubCategory, updateCategory, updateHistory } from './SpiActions'
import { updateRadius, updateCenter, saveCoordParams } from './MapActions'
import { fetchSigns, geoJson, getCounts } from '../../utils/api/getSigns'
import turf from 'turf'

export const handleFilterSearchHistory = value => dispatch => {
  const { searchHistory } = getLatestState()
  if (value) {
    let filteredHistory = searchHistory.filter(search =>
      search.address.toLowerCase().match(value.toLowerCase())
    )
    dispatch(filterSearchHistory(filteredHistory))
  } else {
    dispatch(filterSearchHistory([]))
  }
}

export const convertAddressToCenter = address => async dispatch => {
  try {
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results[0])
    dispatch(updateCenter([latLng.lng, latLng.lat]))
  } catch (error) {
    dispatch(updateLoading(false))
    notify({
      content: 'Geolocation Error',
      type: 'Error',
      className: 'error-alert',
    })
  }
}

export const getNumberOfSigns = latLng => async dispatch => {
  const { category, radius } = getLatestState()
  let newRadius = validateRadius(radius)
  try {
    const response = await getCounts(
      latLng,
      category[1],
      newRadius,
      '',
      '',
      geoJson
    )
    await dispatch(updateSignCounts(response.data.counts))
  } catch (err) {
    console.log(err)
  }
}

const sanitizeLatLng = latLng => {
  if (latLng.split(',').length > 1) {
    let parsedlatLng = latLng
      .split(',')
      .map(coord => coord.split(':').map(latLng => parseFloat(latLng)))
    let polygon = turf.polygon([parsedlatLng])
    return turf.centroid(polygon).geometry.coordinates
  } else {
    return latLng.split(':').map(coord => parseFloat(coord))
  }
}

export const populateStreets = signs => dispatch => {
  let streets = []
  signs.forEach(sign => {
    let { on_street, from_street, to_street } = sign.properties
    let on_street_object = { key: on_street, value: on_street, text: on_street }
    let from_street_object = {
      key: from_street,
      value: from_street,
      text: from_street,
    }
    let to_street_object = { key: to_street, value: to_street, text: to_street }
    streets = uniqBy(
      [...streets, on_street_object, to_street_object, from_street_object],
      'key'
    )
  })
  dispatch({ type: UPDATE_STREETS, value: streets })
}

export const getSigns = latLng => async dispatch => {
  const { category, compassRotate } = getLatestState()
  await dispatch(filterSearchHistory([]))
  const center = sanitizeLatLng(latLng)
  await dispatch(updateCenter([center[0], center[1]]))
  await dispatch(preSearchTasks([center[1], center[0]]))
  try {
    const response = await fetchSigns(latLng, category[1], 200, '', '', geoJson)
    if (response.status === 200 && response.data && response.data.features) {
      let data = response.data.features
      let validResponseNotification = `Found ${data.length} Signs in this location.`
      notify({
        content: validResponseNotification,
        type: 'Success',
        className: 'success-alert',
      })
      await dispatch(
        postValidResponseTasks(data, center[1], center[0], 200, compassRotate)
      )
      dispatch(updateSignCounts(response.data.total))
      dispatch(fetchOrders(data))
      dispatch(populateStreets(data))
    } else {
      await dispatch(updateLoading(false))
      notify({
        content: 'No Signs were found',
        type: 'Info',
        className: 'info-alert',
      })
    }
  } catch (error) {
    await dispatch(updateLoading(false))
    notify({
      content: error.response.data.error.details[0],
      type: 'Error',
      className: 'error-alert',
    })
  }
  const geocoder = new window.google.maps.Geocoder()
  delay(
    coord => {
      geocoder.geocode(
        { location: { lat: coord[1], lng: coord[0] } },
        async (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              let address = results[0].formatted_address
              await dispatch(updateAddress(address))
              await dispatch(updateAddressCache(address))
              await dispatch(
                updateHistory({
                  address: address,
                  url: `/${latLng}/${category.join(',')}`,
                  time: new Date(),
                })
              )
            } else {
              notify({
                content:
                  'Sorry, there was an error with Geocoding service. We could not retrieve the address.',
                type: 'Error',
                className: 'info-alert',
              })
            }
          }
        }
      )
    },
    1000,
    center
  )
}

export const clearSearch = () => async dispatch => {
  await dispatch({ type: RESET_DATA })
}

const filterOrders = (orders, signs) => async dispatch => {
  let filteredOrders = orders
  filteredOrders.forEach(order => {
    order.properties['signs'] = []
    signs.forEach(sign => {
      if (sign.properties.order_number === order.properties.order_number) {
        order.properties['signs'].push(sign)
      }
    })
  })

  // Filter orders with at least one sign
  filteredOrders = filteredOrders.filter(
    order => order.properties.signs.length > 0
  )

  await dispatch(updateOrdersCache(filteredOrders))
}

export const filterSignsBySub = sub => async (dispatch, getState) => {
  const { signsState } = getState()
  const signs = signsState.signs
  const filtered = signsState.subFiltered
  const orders = signsState.orders
  await dispatch(updateSubCategory(sub))
  if (sub === '') {
    await dispatch(updateSignsCache({ signs: signs }))
    await dispatch(filterOrders(orders, signs))
    await dispatch(updateOrdersCache(orders))
    await dispatch(populateStreets(signs))
  } else {
    await dispatch(updateSignsCache({ signs: filtered[sub] }))
    await dispatch(filterOrders(orders, filtered[sub]))
    await dispatch(updateOrdersCache(orders))
    await dispatch(populateStreets(filtered[sub]))
  }
}

// Provided a sign this function returns a fake single order for it
const buildFakeOrder = sign => {
  const { coordinates } = sign.geometry
  const { smo_code } = sign.properties
  return {
    geometry: {
      type: 'Point',
      coordinates: JSON.stringify(coordinates),
    },
    properties: {
      ...sign.properties,
      signs: [],
      object_key: smo_code,
    },
    type: 'Feature',
  }
}

// Provided Array of signs this function Builds just Orders from signs
const buildOrders = signs =>
  signs.reduce((orders, sign) => {
    let order = buildFakeOrder(sign)
    orders.push(order)
    return uniqBy(orders, ord => ord.properties.order_number)
  }, [])

// Provided Signs this function Creates fake array of Orders with respective signs and returns Array of Orders
const buildOrdersFromSigns = signs =>
  buildOrders(signs).map(order => {
    signs.forEach(sign => {
      if (sign.properties.order_number === order.properties.order_number) {
        order.properties.signs.push(sign)
      }
    })
    return order
  })

const fetchOrders = signs => async dispatch => {
  let orders = buildOrdersFromSigns(signs)
  await dispatch(updateOrders(orders))
  await dispatch(updateOrdersCache(orders))
}

export const getFromParamsData = (
  category,
  latLng,
  radius
) => async dispatch => {
  await dispatch(saveCoordParams(latLng))
  await dispatch(updateCategory(category))
  await dispatch(updateRadius(radius))
  await dispatch(getSigns(latLng))
}
