import combinedStore from '../../index'

export const allState = () => {
  return combinedStore.getState()
}

export const getLatestState = () => {
  const { spi, map, signsState } = combinedStore.getState()
  const { category, subCategory, compassRotate, searchHistory } = spi
  const { cache, radius, zoom, center, coordParams } = map
  const subType = subCategory.length > 1 ? subCategory[1] : ''
  return {
    category: category,
    sub: subType,
    radius: radius,
    address: signsState.address,
    zoom: zoom,
    compassRotate: compassRotate,
    mapCache: cache,
    center: center,
    signsCache: signsState.cache.signs,
    signs: signsState.signs,
    orders: signsState.orders,
    ordersCache: signsState.cache.orders,
    searchHistory: searchHistory,
    coordParams: coordParams,
  }
}
