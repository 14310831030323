import axios from 'axios'
import { apiUsername, apiPassword, signURL } from '../../config'

export const geoJson = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/geojson',
    Accept: '*/*',
  },
}

export const csv = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/csv',
    Accept: '*/*',
  },
  data: {},
}

export const fetchSigns = (
  latLng,
  category,
  radius,
  address,
  subtype = '',
  headers
) => {
  let results = axios.get(signURL, {
    auth: {
      username: apiUsername,
      password: apiPassword,
    },
    params: {
      coord: latLng,
      category: category,
      near_by: false,
      address: address,
      location_service: false,
      distance: radius,
      sign_standard_subtype_id: subtype,
    },
    ...headers,
  })
  return results
}

export const getCounts = (
  latLng,
  category,
  radius,
  address,
  subtype = '',
  headers
) => {
  let results = axios.get(signURL, {
    auth: {
      username: apiUsername,
      password: apiPassword,
    },
    params: {
      coord: latLng,
      category: category,
      near_by: false,
      address: address,
      location_service: false,
      distance: radius,
      sign_standard_subtype_id: subtype,
      get_counts: true,
    },
    ...headers,
  })
  return results
}
