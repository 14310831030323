import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Contents from './Contents'
import SignCategories from '../SignCategories'
import Head from '../Head'
import GetParams from './GetParams'
import { disablePinchToZoomIOS } from '../../utils/browserBehaviour'
import 'react-block-ui/style.css'

class App extends Component {
  componentWillMount = () => {
    disablePinchToZoomIOS(window)
  }

  render() {
    return (
      <SignCategories>
        <Head />
        <Route path="/" component={Contents} />
        <Switch>
          <Route path="/:latLng/:category/:radius" component={GetParams} />
          <Route path="/" component={GetParams} />
        </Switch>
      </SignCategories>
    )
  }
}

export default App
