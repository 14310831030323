import { persistentReducer } from 'redux-pouchdb'
import {
  UPDATE_CATEGORIES,
  UPDATE_SUBCATEGORIES,
  UPDATE_CATEGORY,
  UPDATE_MYLOCATION,
  UPDATE_WATCHLOCATION,
  UPDATE_COMPASSROTATE,
  UPDATE_SUBCATEGORY,
  TOGGLE_SHAKE_SEARCH,
  CHANGE_THEME,
  NETWORK_STATUS,
  TOGGLE_DRAWING,
  UPDATE_HISTORY,
} from '../actionTypes/SpiActionTypes'
import { uniqBy, take } from 'lodash'
import { theme, drawEnabledDefault } from '../../config'

const initialState = {
  categories: [],
  subCategories: [],
  category: ['parking', 21],
  subCategory: '',
  myLocation: false,
  watchLocation: false,
  compassRotate: false,
  shakeSearch: false,
  theme: theme,
  online: true,
  drawEnabled: drawEnabledDefault,
  searchHistory: [],
}

const Spi = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CATEGORIES:
      return {
        ...state,
        categories: action.categories.filter(
          category => category.description !== 'streetname'
        ),
      }
    case UPDATE_SUBCATEGORIES:
      return {
        ...state,
        subCategories: action.subCategories,
      }
    case UPDATE_CATEGORY:
      return {
        ...state,
        category: action.category,
      }
    case UPDATE_MYLOCATION:
      return {
        ...state,
        myLocation: action.value,
      }
    case UPDATE_WATCHLOCATION:
      return {
        ...state,
        watchLocation: action.value,
      }
    case UPDATE_COMPASSROTATE:
      return {
        ...state,
        compassRotate: action.value,
      }
    case UPDATE_SUBCATEGORY:
      return {
        ...state,
        subCategory: action.value,
      }
    case TOGGLE_SHAKE_SEARCH:
      return {
        ...state,
        shakeSearch: action.value,
      }
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.theme,
      }
    case NETWORK_STATUS:
      return {
        ...state,
        online: action.value,
      }
    case TOGGLE_DRAWING:
      return {
        ...state,
        drawEnabled: action.value,
      }
    case UPDATE_HISTORY:
      return {
        ...state,
        searchHistory: take(
          uniqBy([action.value, ...state.searchHistory], 'address'),
          50
        ),
      }
    default:
      return state
  }
}

export default persistentReducer(Spi)
