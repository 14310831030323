import { filter, uniq } from 'lodash'
import Colors from './PieChartColors'

export const generatePieData = signs => {
  return new Promise((resolve, reject) => {
    let labels = uniq(signs.map(sign => sign.properties.smo_subtype))
    let data = labels.map(
      label =>
        filter(signs, sign => sign.properties.smo_subtype === label).length
    )
    let colors = Colors.slice(0, labels.length)
    let pieData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    }
    resolve(pieData)
  })
}
