export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES'
export const UPDATE_SUBCATEGORIES = 'UPDATE_SUBCATEGORIES'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_MYLOCATION = 'UPDATE_MYLOCATION'
export const UPDATE_WATCHLOCATION = 'UPDATE_WATCHLOCATION'
export const UPDATE_COMPASSROTATE = 'UPDATE_COMPASSROTATE'
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY'
export const TOGGLE_SHAKE_SEARCH = 'TOGGLE_SHAKE_SEARCH'
export const CHANGE_THEME = 'CHANGE_THEME'
export const NETWORK_STATUS = 'NETWORK_STATUS'
export const TOGGLE_DRAWING = 'TOGGLE_DRAWING'
export const UPDATE_HISTORY = 'UPDATE_HISTORY'
