import { PureComponent } from 'react'
import browserUpdate from 'browser-update'

class BrowserUpdate extends PureComponent {
  componentDidMount = () => {
    const config = {
      required: {
        e: 0, // Latest Edge (current (11))
        f: 0, // Latest Firefox (current 60)
        o: 0, // Latest Opera (current 53)
        s: 0, // Latest Safari (current 11)
        c: 0, // Latest Chrome (current 67)
        i: 0, // Latest Internet Explorer (current 11)
      },
      insecure: true,
      style: 'top',
      reminder: 0,
      newwindow: true,
      noclose: true,
      no_permanent_hide: false,
      shift_page_down: true,
      onshow: infos => {
        this.props.oldBrowserDetected(true)
      },
    }
    browserUpdate(config)
  }

  render() {
    return null
  }
}

export default BrowserUpdate
