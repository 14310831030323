import axios from 'axios'
import { apiUsername, apiPassword, categoryUrl, subTypeUrl } from '../../config'

export const fetchCategories = () => {
  let results = axios.get(categoryUrl, {
    auth: {
      username: apiUsername,
      password: apiPassword,
    },
  })
  return results
}

export const fetchSubCategories = asset_subtype_id => {
  let results = axios.get(subTypeUrl, {
    auth: {
      username: apiUsername,
      password: apiPassword,
    },
    params: {
      asset_subtype_id: asset_subtype_id,
    },
  })
  return results
}
