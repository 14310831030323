import {
  updateLoading,
  updateSigns,
  getStreetViewImage,
  updateSignsCache,
  filterBySub,
} from '../SignsActions'
import { updateSubCategory } from '../SpiActions'
import { updateCache, updateClusterColor } from '../MapActions'
import { generatePieData } from '../../../utils/generateChartData'
import {
  toggleSearchContent,
  toggleStreetView,
  updateChartData,
  toggleCyclorama,
} from '../UiActions'
import { CLEAR_ORDERS } from '../../actionTypes/SignsActionTypes'
import { cacheItems } from './index'

export const preSearchTasks = center => async dispatch => {
  await dispatch(updateLoading(true))
  await dispatch({ type: CLEAR_ORDERS })
  await dispatch(updateSignsCache({ signs: [], orders: [] }))
  await dispatch(updateSigns([]))
  await dispatch(toggleStreetView(false))
  await dispatch(toggleSearchContent(true))
  await dispatch(getStreetViewImage(center))
  await dispatch(updateClusterColor(null))
  await dispatch(updateSubCategory(''))
  await dispatch(toggleCyclorama(false))
}

export const postValidResponseTasks = (
  response,
  lat,
  lng,
  radius,
  compassRotate
) => async dispatch => {
  const pieData = await generatePieData(response)
  await dispatch(updateChartData(pieData))
  await dispatch(updateSigns(response))
  await dispatch(updateSignsCache({ signs: response }))
  await dispatch(filterBySub(response))
  await dispatch(updateCache(cacheItems(lat, lng, radius)))
  await dispatch(updateLoading(false))
}
