import React from 'react'
import { string } from 'prop-types'

const General = ({ content }) => <p>{content}</p>

General.propTypes = {
  content: string.isRequired,
}

export default General
