import {
  SET_MAP_REFERENCE,
  UPDATE_ZOOM,
  UPDATE_CENTER,
  UPDATE_RADIUS,
  UPDATE_CACHE,
  UPDATE_ANGLE,
  UPDATE_CLUSTER_COLOR,
  UPDATE_STREET_VIEW_CENTER,
  UPDATE_INFOBOX,
  SAVE_COORD_PARAMS,
  UPDATE_POLYGON,
  TOGGLE_SATELLITE,
  ADDRESS_BASED_SEARCH,
} from '../actionTypes/MapActionTypes'
import { RESET_DATA as RESET_INFOBOX } from '../actionTypes/SignsActionTypes'
import { maxRadius } from '../../config'

const initialState = {
  mapReference: null,
  statellite: false,
  streetViewCenter: {
    lat: 40.703322600000064,
    lng: -74.00889620000004,
  },
  center: [-74.00889620000004, 40.703322600000064],
  zoom: 18,
  radius: maxRadius,
  cache: {
    center: [-74.00889620000004, 40.703322600000064],
    radius: maxRadius,
  },
  angle: 0,
  clusterColor: null,
  infobox: {
    coordinates: null,
    signs: null,
    sign: null,
    type: null,
  },
  polygon: '',
  addressBasedSearch: false,
  coordParams:
    '-74.01075831739568:40.702566476096536,-74.01075831739568:40.70408487051455,-74.00706538316705:40.70408487051455,-74.00706538316705:40.702566476096536,-74.01075831739568:40.702566476096536',
}

const MapState = (state = initialState, action) => {
  switch (action.type) {
    case RESET_INFOBOX:
      return {
        ...state,
        infobox: { ...initialState.infobox },
      }
    case SET_MAP_REFERENCE:
      return {
        ...state,
        mapReference: action.mapReference,
      }
    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: action.value,
      }
    case UPDATE_CENTER:
      return {
        ...state,
        center: action.value,
      }
    case UPDATE_RADIUS:
      return {
        ...state,
        radius: action.radius,
      }
    case UPDATE_CACHE:
      return {
        ...state,
        cache: action.value,
      }
    case UPDATE_ANGLE:
      return {
        ...state,
        angle: action.value,
      }
    case UPDATE_CLUSTER_COLOR:
      return {
        ...state,
        clusterColor: action.value,
      }
    case UPDATE_STREET_VIEW_CENTER:
      return {
        ...state,
        streetViewCenter: action.value,
      }
    case UPDATE_INFOBOX:
      return {
        ...state,
        infobox: action.value,
      }
    case SAVE_COORD_PARAMS:
      return {
        ...state,
        coordParams: action.value,
      }
    case UPDATE_POLYGON:
      return {
        ...state,
        polygon: action.value,
      }
    case TOGGLE_SATELLITE:
      return {
        ...state,
        satellite: action.value,
      }
    case ADDRESS_BASED_SEARCH:
      return {
        ...state,
        addressBasedSearch: action.value,
      }
    default:
      return state
  }
}

export default MapState
