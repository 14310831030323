import React from 'react'
import { Card } from 'semantic-ui-react'
import { dotSite } from '../../../../config'

const Disclaimer = () => (
  <Card fluid>
    <Card.Content textAlign="center" header="Disclaimer" />
    <Card.Content>
      <p>
        The information contained in SIMS and the information shown here was
        compiled by the:{' '}
        <a
          id="dot-link"
          target="_blank"
          rel="noopener noreferrer"
          href={dotSite}
        >
          New York City Department of Transportation
        </a>
        for governmental purposes. The information is updated as soon as
        reasonably practicable. This Application also makes use of a pre-release
        version of the New York City Department of City Planning’s Geosupport
        System. The Departments of Transportation and City Planning and the City
        make no representation as to the accuracy of the information or its
        suitability for any purpose and disclaim any liability for omissions or
        errors that may be contained therein. The public is advised that parking
        regulations are subject to temporary or permanent change and that posted
        signage must be observed for compliance with laws and regulations.
      </p>
    </Card.Content>
  </Card>
)

export default Disclaimer
