import {
  TOGGLE_SIDEBAR,
  TOGGLE_SEARCH_CONTENT,
  UPDATE_SHOW_ADDRESS_SEARCH,
  TOGGLE_SEARCH_CONTENT_FOOTER,
  TOGGLE_STREET_VIEW,
  UPDATE_CHART_DATA,
  TOGGLE_SUBTYPE_MENU,
  TOGGLE_CYCLORAMA,
  TOGGLE_DOWNLOADS,
  TOGGLE_LIST,
  IS_IE,
  FILTER_STREETS_VALUE,
} from '../actionTypes/UiActionTypes'
import { notify } from './Utils'
import { updateSignsCache, updateOrdersCache } from './SignsActions'
import { isIE } from '../../utils/detect_ie'
import { populateStreets } from './index'

const ie = () => ({ type: IS_IE, value: true })

//EXPORTS///////////////////////////////////////////////////////////////////////
export const is_ie = () => dispatch => {
  if (isIE() === true) {
    dispatch(ie())
    notify(
      {
        content:
          "For better experience, Please use Google Chrome or any other Modern Browser. Some advanced tools like 'Polygon Draw', 'My location' are not available in Internet Explorer.",
        type: 'Error',
        className: 'error-alert',
      },
      false
    )
  }
}

export const toggleList = value => ({ type: TOGGLE_LIST, value })
export const toggleSubTypeMenu = value => ({ type: TOGGLE_SUBTYPE_MENU, value })
export const toggleSidebar = value => ({ type: TOGGLE_SIDEBAR, value })
export const toggleSearchContent = value => ({
  type: TOGGLE_SEARCH_CONTENT,
  value,
})
export const toggleSearchContentFooter = value => ({
  type: TOGGLE_SEARCH_CONTENT_FOOTER,
  value,
})
export const updateShowAddressSearch = show => ({
  type: UPDATE_SHOW_ADDRESS_SEARCH,
  show,
})
export const toggleStreetViewInstance = show => ({
  type: TOGGLE_STREET_VIEW,
  show,
})
export const updateChartData = chartData => ({
  type: UPDATE_CHART_DATA,
  chartData,
})
export const toggleCycloramaInstance = value => ({
  type: TOGGLE_CYCLORAMA,
  value,
})
export const toggleDownloads = value => ({ type: TOGGLE_DOWNLOADS, value })

export const toggleStreetView = show => async dispatch => {
  if (!show) {
    await dispatch(toggleSearchContent(true))
  } else {
    await dispatch(toggleSearchContent(false))
  }
  await dispatch(toggleStreetViewInstance(show))
}

export const toggleCyclorama = value => async dispatch => {
  if (!value) {
    await dispatch(toggleSearchContent(true))
  } else {
    await dispatch(toggleSearchContent(false))
  }
  await dispatch(toggleCycloramaInstance(value))
}

export const handleCyclorama = value => async dispatch => {
  await dispatch(toggleSearchContentFooter(false))
  await dispatch(toggleCyclorama(value))
}

export const handleToggleSearchContent = value => async dispatch => {
  await dispatch(toggleSearchContentFooter(value))
  await dispatch(toggleList(value))
}

export const handleToggleList = value => async (dispatch, getState) => {
  const { signsState } = getState()
  await populateStreets(signsState.signs)
  await dispatch(updateSignsCache({ signs: signsState.signs }))
  await dispatch(updateOrdersCache(signsState.orders))
  await dispatch(toggleList(value))
  await dispatch(toggleCyclorama(false))
  await dispatch(toggleStreetView(false))
  await dispatch({ type: FILTER_STREETS_VALUE, value: [] })
}

export const filterStreetsValue = value => ({
  type: FILTER_STREETS_VALUE,
  value,
})
