export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_SEARCH_CONTENT = 'TOGGLE_SEARCH_CONTENT'
export const TOGGLE_SEARCH_CONTENT_FOOTER = 'TOGGLE_SEARCH_CONTENT_FOOTER'
export const UPDATE_SHOW_ADDRESS_SEARCH = 'SHOW_ADDRESS_SEARCH'
export const TOGGLE_STREET_VIEW = 'TOGGLE_STREET_VIEW'
export const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
export const TOGGLE_SUBTYPE_MENU = 'TOGGLE_SUBTYPE_MENU'
export const TOGGLE_CYCLORAMA = 'TOGGLE_CYCLORAMA'
export const TOGGLE_DOWNLOADS = 'TOGGLE_DOWNLOADS'
export const TOGGLE_LIST = 'TOGGLE_LIST'
export const IS_IE = 'is_ie'
export const RESET_DATA = 'RESET_DATA'
export const FILTER_STREETS_VALUE = 'FILTER_STREETS_VALUE'
