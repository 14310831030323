//Api Auth//////////////////////////////////////////////////////////////////////
export const apiUsername = 'public'
export const apiPassword = 'D0tPu6l1c@p1'
////////////////////////////////////////////////////////////////////////////////

//Api endpoints/////////////////////////////////////////////////////////////////
export const signURL = 'https://nycdotsignsstg.net/api/v1/signs'
export const signOrderUrl = 'https://nycdotsignsstg.net/api/v1/sign_orders'
export const categoryUrl =
  'https://nycdotsignsstg.net/api/v1/sign_standard_categories'
export const subTypeUrl = 'https://nycdotsignsstg.net/api/v1/sign_standard_types'
export const recaptchaVerify = 'https://nycdotsignsstg.net/api/v1/verify_recaptcha'
////////////////////////////////////////////////////////////////////////////////

//Map///////////////////////////////////////////////////////////////////////////
export const mapboxKey =
  'pk.eyJ1IjoibnljZG90IiwiYSI6ImNqODBqMzUycjF5OWsycXMwNmUzMnJwZTAifQ.EKQnfskdfDXHzErKUqCb7A'
export const mapbox = 'mapbox://styles/nycdot/cj80h8bcm7q5q2rmsaomhy9qp'
export const mapboxSatellite =
  'mapbox://styles/nycdot/cjmgp1wc158ge2ro0g0cyvul6'
export const minZoom = 15
export const maxZoom = 22
export const maxBounds = [
  [-74.26, 40.493], // Southwest coordinates
  [-73.7, 40.9155], // Northeast coordinates
]
export const maxRadius = 1500
export const fallBackRadius = 1000
export const minRadius = 300
export const minZoomForSearch = 16.2
////////////////////////////////////////////////////////////////////////////////

//MISC////////////////////////////////////////////////////////////////////////////
export const theme = '#43a047'
export const vibrateDuration = 2000 //in seconds
export const title = 'NYC-Parking Signs Locator'
export const enableColorPicker = true
export const enableLocationTracking = true
export const enableCompass = true
export const enableShakeSearch = true
export const enableDraw = true // enable disable drawing completely
export const drawEnabledDefault = true // set the default value of drawing if enabled
export const enableHistory = true
////////////////////////////////////////////////////////////////////////////////

//GOOGLE////////////////////////////////////////////////////////////////////////////
export const googleApiKey = 'gme-nycdeptoftransportation'
export const streetViewImageUrl =
  'https://maps.googleapis.com/maps/api/streetview?size=600x300&location='
export const googleAnalyticsKey = ''
export const streetViewSecret = '9PV3BQ1PqTv2QiUZhDjnE31i9qw='
////////////////////////////////////////////////////////////////////////////////

//CYCLORAMA////////////////////////////////////////////////////////////////////////////
export const cycloramaUsername = 'SPI'
export const cycloramaPassword = 'c6653awb'
export const cycloramaApiKey =
  'uXgpenc5OzMdm3AZ2rU5mrEJKLoJG_MlODgFuPctaTMV2XsVWr4NC3kh6XoNSr0V'
export const cycloramaSrs = 'EPSG:4326'
export const cycloramaLanguage = 'en-us'
export const enableCyclorama = false
////////////////////////////////////////////////////////////////////////////////

export const enableLogger = false
export const allCategory = false

export const dotSite = 'http://www.nyc.gov/html/dot/html/home/home.shtml'
export const dotContactPage =
  'http://www.nyc.gov/html/dot/html/contact/contact-form.shtml'
export const openData =
  'http://www.nyc.gov/html/dot/html/about/datafeeds.shtml#parking'

export const defaultRadiusToHideTheSearchButton = 200
export const watchPositionDistance = 100

export const disclaimerDays = 7
;export const mapInstance = true
