import React, { PureComponent } from 'react'
import {
  initialize,
  pageview,
  timing,
  exception,
  outboundLink,
  event,
} from 'react-ga'
import { googleAnalyticsKey } from '../../config'
initialize(googleAnalyticsKey)

const withAnalytics = Component => {
  return class Analytics extends PureComponent {
    registerPageView = ({ route }) => {
      pageview(route)
    }

    registerOutboundLink = ({ label }) => {
      outboundLink(
        {
          label,
        },
        () => {
          console.log(label)
        }
      )
    }

    registerException = ({ description, fatal }) => {
      exception({
        description,
        fatal,
      })
    }

    registerTiming = ({ category, variable, value, label }) => {
      timing({
        category,
        variable,
        value,
        label,
      })
    }

    registerEvent = ({ category, action, label }) => {
      event({
        category,
        action,
        label,
      })
    }

    newProps = () => ({
      registerEvent: this.registerEvent,
      registerTiming: this.registerTiming,
      registerPageView: this.registerPageView,
      registerException: this.registerException,
      registerOutboundLink: this.registerOutboundLink,
    })

    render() {
      return <Component {...this.props} {...this.newProps()} />
    }
  }
}

export default withAnalytics
