export const disableBackButton = () => {
  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function(event) {
    window.history.go(1)
  }
}

export const disablePinchToZoomIOS = global => {
  let startY = 0

  let enabled = false

  let supportsPassiveOption = false
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassiveOption = true
      },
    })
    window.addEventListener('test', null, opts)
  } catch (e) {}

  const handleTouchmove = evt => {
    let el = evt.target

    while (el !== document.body && el !== document) {
      const style = window.getComputedStyle(el)

      if (!style) {
        break
      }

      if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
        return
      }

      const scrolling = style.getPropertyValue('-webkit-overflow-scrolling')
      const overflowY = style.getPropertyValue('overflow-y')
      const height = parseInt(style.getPropertyValue('height'), 10)

      const isScrollable =
        scrolling === 'touch' &&
        (overflowY === 'auto' || overflowY === 'scroll')
      const canScroll = el.scrollHeight > el.offsetHeight

      if (isScrollable && canScroll) {
        const curY = evt.touches ? evt.touches[0].screenY : evt.screenY
        const isAtTop = startY <= curY && el.scrollTop === 0
        const isAtBottom =
          startY >= curY && el.scrollHeight - el.scrollTop === height

        if (isAtTop || isAtBottom) {
          evt.preventDefault()
        }

        return
      }

      el = el.parentNode
    }

    evt.preventDefault()
  }

  const handleTouchstart = evt => {
    startY = evt.touches ? evt.touches[0].screenY : evt.screenY
  }

  const enable = () => {
    window.addEventListener(
      'touchstart',
      handleTouchstart,
      supportsPassiveOption
        ? {
            passive: false,
          }
        : false
    )
    window.addEventListener(
      'touchmove',
      handleTouchmove,
      supportsPassiveOption
        ? {
            passive: false,
          }
        : false
    )
    enabled = true
  }

  const disable = () => {
    window.removeEventListener('touchstart', handleTouchstart, false)
    window.removeEventListener('touchmove', handleTouchmove, false)
    enabled = false
  }

  const isEnabled = () => enabled

  const testDiv = document.createElement('div')
  document.documentElement.appendChild(testDiv)
  testDiv.style.WebkitOverflowScrolling = 'touch'
  const scrollSupport =
    'getComputedStyle' in window &&
    window.getComputedStyle(testDiv)['-webkit-overflow-scrolling'] === 'touch'
  document.documentElement.removeChild(testDiv)

  if (scrollSupport) {
    enable()
  }

  const iNoBounce = {
    enable,
    disable,
    isEnabled,
  }

  if (typeof module !== 'undefined' && module.exports) {
    module.exports = iNoBounce
  }
  if (typeof global.define === 'function') {
    ;(define => {
      define('iNoBounce', [], () => iNoBounce)
    })(global.define)
  } else {
    global.iNoBounce = iNoBounce
  }
}
