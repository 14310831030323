import {
  STREETS_SMART_API_LOADED,
  CYCLORAMA_LOADING,
  RESET_DATA,
} from '../actionTypes/CycloramaActionTypes'
import { NETWORK_STATUS } from '../actionTypes/SpiActionTypes'

const initialState = {
  streetsSmartApi: false,
  cycloramaLoading: false,
}

const CycloramaScripts = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DATA:
      return {
        ...state,
        ...initialState,
      }
    case STREETS_SMART_API_LOADED:
      return {
        ...state,
        streetsSmartApi: action.value,
      }
    case CYCLORAMA_LOADING:
      return {
        ...state,
        cycloramaLoading: action.value,
      }
    case NETWORK_STATUS:
      if (!action.value) {
        return {
          ...state,
          cycloramaLoading: action.value,
          streetsSmartApi: action.value,
        }
      } else {
        return {
          ...state,
        }
      }
    default:
      return state
  }
}

export default CycloramaScripts
