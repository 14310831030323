import { streetViewSecret } from '../config'
import { HmacSHA1, enc } from 'crypto-js'
import url from 'url'

const removeWebSafe = safeEncodedString =>
  safeEncodedString.replace(/-/g, '+').replace(/_/g, '/')

const makeWebSafe = encodedString =>
  encodedString.replace(/\+/g, '-').replace(/\//g, '_')

// Decode Base64 for the secret
const decodeBase64Hash = code => enc.Base64.parse(code)

// Encode HMAC-SHA1 using the secret
const encodeBase64Hash = (key, data) => HmacSHA1(data, key).toString(enc.Base64)

// Generate the signature
const signature = parsedUrl =>
  makeWebSafe(
    encodeBase64Hash(
      decodeBase64Hash(removeWebSafe(streetViewSecret)),
      parsedUrl.path
    )
  )

// Export the signed URL
export const signUrl = path => {
  const parsedUrl = url.parse(path)
  return `${url.format(parsedUrl)}&signature=${signature(parsedUrl)}`
}
