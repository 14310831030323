export const SET_MAP_REFERENCE = 'SET_MAP_REFERENCE'
export const UPDATE_ZOOM = 'UPDATE_ZOOM'
export const UPDATE_CENTER = 'UPDATE_CENTER'
export const UPDATE_RADIUS = 'UPDATE_RADIUS'
export const UPDATE_CACHE = 'UPDATE_CACHE'
export const UPDATE_ANGLE = 'UPDATE_ANGLE'
export const UPDATE_CLUSTER_COLOR = 'UPDATE_CLUSTER_COLOR'
export const UPDATE_STREET_VIEW_CENTER = 'UPDATE_STREET_VIEW_CENTER'
export const UPDATE_INFOBOX = 'UPDATE_INFOBOX'
export const SAVE_COORD_PARAMS = 'SAVE_COORD_PARAMS'
export const UPDATE_POLYGON = 'UPDATE_POLYGON'
export const TOGGLE_SATELLITE = 'TOGGLE_SATELLITE'
export const ADDRESS_BASED_SEARCH = 'ADDRESS_BASED_SEARCH'
